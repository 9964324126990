import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { IUserProcessDetails, StepRun } from '@/interfaces/user-process';
import { IWorkReport } from '@/interfaces/work-report';

export interface IProcessDetailsState {
  isTimelinePending: boolean;
  isPending: boolean;
  isRejected: boolean;
  isTerminated: boolean;
  isArchived: boolean;
  archiveError: null | string;
  restoreError: null | string;
  isTerminating: boolean;
  terminationStage: null | 'pending' | 'terminated';
  terminationError: null | string;
  terminationErrorMsg?: string;
  errorMsg: string;
  processDetails: IUserProcessDetails | null;
  timelineReport?: IWorkReport;
  selectedStepRun: StepRun | null;
}

const initialState: IProcessDetailsState = {
  isTimelinePending: true,
  isPending: false,
  isRejected: false,
  isTerminated: false,
  isTerminating: false,
  isArchived: false,
  archiveError: null,
  restoreError: null,
  terminationStage: null,
  terminationError: null,
  errorMsg: '',
  processDetails: null,
  selectedStepRun: null,
};

export class ProcessDetailsReducer extends ImmerReducer<IProcessDetailsState> {
  public setIsPending() {
    this.draftState.isPending = true;
    this.draftState.isRejected = false;
  }

  public setIsTimelinePending() {
    this.draftState.isTimelinePending = true;
    this.draftState.isRejected = false;
  }

  public setTerminationStage(stage: IProcessDetailsState['terminationStage']) {
    this.draftState.terminationStage = stage;
  }

  public setTerminationError(error: string | null) {
    this.draftState.terminationError = error;
  }

  public archive() {
    this.draftState.isArchived = true;
  }

  public setArchiveError(msg: string) {
    this.draftState.archiveError = msg;
  }

  public restore() {
    this.draftState.isArchived = false;
  }

  public setRestoreError(msg: string) {
    this.draftState.restoreError = msg;
  }

  public setProcessDetails(processDetails: IUserProcessDetails) {
    this.draftState.isPending = false;
    this.draftState.processDetails = processDetails;
    this.draftState.isArchived = !!processDetails.archivedBy;
    if (processDetails.terminatedBy) {
      this.draftState.isTerminated = true;
    }

    if (processDetails.stepRuns.length === 1) {
      this.draftState.selectedStepRun = processDetails.stepRuns[0];
    }
  }

  public setTimelineReport(timelineReport: IWorkReport) {
    this.draftState.isTimelinePending = false;
    this.draftState.timelineReport = timelineReport;
  }

  public setIsRejected(errMsg: string) {
    this.draftState.isPending = false;
    this.draftState.isRejected = true;
    this.draftState.errorMsg = errMsg;
  }

  public setSelectedStepRun(stepRun: StepRun | null) {
    this.draftState.selectedStepRun = stepRun;
  }

  public reset() {
    this.draftState = {
      ...initialState,
      isTerminated: this.draftState.isTerminated,
      isArchived: this.draftState.isArchived
    };
  }
}

export default createReducerFunction(ProcessDetailsReducer, initialState);
