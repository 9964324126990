import { createTheme, ThemeOptions } from '@mui/material/styles';

// https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
declare module '@mui/material/styles' {
  interface TypographyVariants {
    footerLinks: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    footerLinks?: React.CSSProperties;
  }

  interface Palette {
    highlight: Palette['primary'];
  }
  interface PaletteOptions {
    highlight: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    footerLinks: true;
  }
}

const componentsTSIssues: ThemeOptions['components'] = {
  MuiTimelineContent: {
    styleOverrides: {
      root: {
        margin: "auto"
      }
    }
  },
  MuiTabPanel: {
    styleOverrides: {
      root: {
        padding: "0",
        width: "100%",
        borderRadius: ".5rem"
      }
    }
  },
  MuiBox: {
    styleOverrides: {
      root: {
      }
    }
  }
} as ThemeOptions['components'];

export const empower = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  palette: {
    primary: {
      main: '#333',
    },
    secondary: {
      main: '#FFF',
      light: '#F5F5F5',
    },
    highlight: {
      main: '#3898EC',
    },
    background: {
      default: '#EBEEF1',
      paper: '#FFFFFF',
    },
    info: { main: "#83839c"},
    error: { main: '#EE4B2B' },
    // warning - used to represent potentially dangerous actions or important messages.
    // info - used to present information to the user that is neutral and not necessarily important.
    // success - used to indicate the successful completion of an action that user triggered.
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  components: {
    // Let's keep CssBaseline up here at the top, and alphabetize the remaining
    // 'component' property sets.
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: "0rem"
        },
        main: {
          width: '100%',
          padding: "1.5rem",
          marginTop: "100px"
        },
        pre: {
          fontSize: "0.875rem",
          padding: "1rem",
          overflow: "auto"
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem",
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          cursor: "pointer",
          borderWidth: "2px",
          borderColor: theme.palette.primary.light,
          borderStyle: "solid",
          borderRadius: "24px",
          fontSize: "16px",
          fontWeight: "600",
          marginLeft: "12px",
          padding: "0 16px",
          textTransform: 'none',
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
          }
        }),
        containedSizeLarge: ({theme}) =>({
          alignItems: "center",
          backgroundColor: theme.palette.primary.main,
          backgroundImage: "linear-gradient(135deg,#5a4c7a,#204990)",
          borderRadius: "50px",
          color: theme.palette.secondary.main,
          display: "flex",
          fontWeight: "300",
          justifyContent: "center",
          lineHeight: "1.5",
          minWidth: "110px",
          padding: "18px 68px",
          textTransform: "capitalize",
          transition: "box-shadow .3s,transform .3s",
          whiteSpace: "nowrap",
          "&.Mui-disabled": {
            cursor: "not-allowed",
            backgroundColor: theme.palette.primary.main,
            opacity: "0.7",
            color: theme.palette.secondary.light,
            borderColor: "#ebedf2"
          }
        }),
        outlinedSecondary: ({theme}) => ({
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.primary.main,
        })
      }
    },
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          padding: "0"
        },
        root: {
          padding: ".5rem 0"
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          width: '100%',
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 100,
          padding: 4
        }
      }
    },
    MuiTab: {
      styleOverrides:{
        root: ({ theme }) => ({})
      }
    },
    MuiTableHead:{
      styleOverrides:{
        root: ({ theme }) => ({
          background: theme.palette.primary.main,
        })
      }
    },
    MuiTableCell: {
      styleOverrides:{
        root: ({ theme }) => ({
          background: theme.palette.secondary.main,
        }),
        head: ({ theme }) => ({
          background: theme.palette.secondary.light,
          textTransform: "uppercase",
        })
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.secondary.light,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.secondary.dark,
            },
            '&:hover fieldset': {
              borderColor: theme.palette.primary.dark
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.primary.main
            },
          }
        }),
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: ({ theme }) => ({
          marginTop: "0",
          backgroundColor: theme.palette.secondary.main,
          padding: "0 8px",
        }),
        formControl: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.light,
          marginTop: "0.5rem"
        }),
        sizeSmall: {
          marginTop: "0"
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'filled' && {
              backgroundColor: theme.palette.secondary.light,
              borderRadius: "5px",
              padding:"0.5rem 1rem 1rem 1rem",
              marginTop:"10px"
            }),
        }),
      }
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          mb: "5px",
          cursor: "pointer",
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          width: "100%"
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          justifyContent: "space-between",
          alignItems: "center",
          padding: "12px",
          paddingLeft: "16px",
          paddingRight: "16px",
          background: theme.palette.background.paper,
          borderRadius: "8px",
          boxShadow: "none"
        })
      },
    },
    MuiSkeleton:{
      styleOverrides:{
        rectangular:{
          borderRadius: "6px",
          minHeight: "64px"
        }
      }
    },
    MuiAlert:{
      styleOverrides:{
        root:{
          textAlign: "left"
        },
        message:{
          textAlign: "left"
        },
        action:{
          textAlign: "left"
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          position: "fixed",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "60px",
          paddingRight: "60px",
          paddingTop: "24px",
          paddingBottom: "24px",
          borderRadius: "4px",
          backgroundColor: theme.palette.secondary.main,
          boxShadow: `2px 2px 10px ${theme.palette.secondary.dark}`
        }),
      },
    },
    MuiDialog:{
      styleOverrides:{
        paper:{
          padding: 20
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          lineHeight: '2.0rem',
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          minWidth: '500px'
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          alignSelf: 'center',
          marginBottom: '16px'
        }
      }
    },
    MuiLink:{
      styleOverrides:{
        root: ({ theme }) => ({
          color: theme.palette.highlight.main,
          fontWeight: "normal",
          lineHeight: "unset",
          cursor: "pointer",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline"
          }
        }),
      }
    },
    MuiList:{
      styleOverrides:{
        root:{
          overflow: "scroll",
          width: "100%",
          maxHeight: 600
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          lineHeight: "unset",
          width: "auto",
          ...(ownerState.variant === "footerLinks" && {
            display: "block",
            paddingTop: "1rem",
            "> a": {
              padding: "0 .5rem",
              color: theme.palette.highlight.main,
              "&:hover": {
                color: theme.palette.highlight.dark,
              }
            }
          })
        }),
        subtitle1: {
          fontWeight: "600",
        },
        overline: {
          fontSize: "0.875rem",
          fontWeight: "600",
          marginBottom: "0.25rem"
        },
        h1: {
          fontWeight: "700",
          fontSize: "30px",
          lineHeight: "35px",
          textAlign: "center"
        },
        h2: {
          fontSize: "1.375rem",
          fontWeight: 700,
        },
        h3: {
          lineHeight: '1.75rem',
          fontSize: '18px',
          fontWeight: '500',
        },
        subtitle2: {
          color: "#83839C",
          fontWeight: "normal",
          fontSize: 12,
          lineHeight: "unset"
        },
        body1:{fontSize: "1rem"},
        body2:{fontSize: 12},
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "initial",
          padding: "0",
          "&.centeredContainer": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh"
          }
        }
      }
    },
    MuiDrawer:{
      styleOverrides:{
        root:{
          minWidth: "600px"
        },
        paper: {
          minWidth: "300px",
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          height: 20
        }
      }
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          marginTop: "1rem",
          padding: "1rem",
          display:"flex",
          justifyContent: "center"
        }
      }
    },
    ...componentsTSIssues
  }
},
);
