import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { ScheduledProcess } from '@/interfaces/scheduled-process';

export interface LegacyScheduledProcessesState {
  isPending: boolean;
  isRejected: boolean;
  processes: ScheduledProcess[];
  shouldMonitorSchedules: boolean
}

const initialState: LegacyScheduledProcessesState = {
  isPending: false,
  isRejected: false,
  processes: [],
  shouldMonitorSchedules: false
};

export class ScheduledProcessesReducer extends ImmerReducer<LegacyScheduledProcessesState> {
  public setIsPending() {
    this.draftState.isPending = true;
    this.draftState.isRejected = false;
  }

  public setProcesses(processes: ScheduledProcess[]) {
    processes.sort((a, b) => a.id - b.id);

    this.draftState.isPending = false;
    this.draftState.processes = processes;
  }

  public setIsRejected() {
    this.draftState.isPending = false;
    this.draftState.isRejected = true;
  }

  public setShouldMonitorSchedules(trueOrFalse: boolean) {
    this.draftState.shouldMonitorSchedules =  trueOrFalse
  }
}

export default createReducerFunction(ScheduledProcessesReducer, initialState);
