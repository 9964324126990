import { createReducerFunction, ImmerReducer } from 'immer-reducer';

export interface ILogsState {
  isPending: boolean;
  logsMap: Record<string, string[]>;
  logs: string[];
  continuationToken: string | null;
  requestId: string | null;
}

export const initialState = {
  isPending: false,
  logsMap: {},
  logs: [],
  continuationToken: null,
  requestId: null,
};

export class LogsReducer extends ImmerReducer<ILogsState> {
  setIsPending(isPending: boolean) {
    this.draftState.isPending = isPending;
  }

  setRequestId(requestId: string | null) {
    this.draftState.requestId = requestId
  }

  addLogs(logs: string[], continuationToken: string | null) {
    const defaultKey = 'initial';
    const key = this.draftState.continuationToken || defaultKey;

    if (key === defaultKey && Object.keys(this.draftState.logsMap).length > 1) {
      this.draftState.logsMap = { [key]: logs };
      this.draftState.continuationToken = null;
    } else {
      this.draftState.logsMap = { ...this.draftState.logsMap, [key]: logs };
      this.draftState.continuationToken = continuationToken;
    }

    this.draftState.logs = Object.values(this.draftState.logsMap).flat();
  }

  reset() {
    this.draftState = { ...initialState };
  }
}

export default createReducerFunction(LogsReducer, initialState);
